import * as React from 'react';
import { Link } from 'gatsby';
import ReactPlayer from 'react-player';
import { Box, Container, Stack, Flex, SimpleGrid } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import PlayIcon from '../../images/play-icon-white.svg';
import '../../styles/nutrients/phosphorus.scss';

const Phosphorus = () => {
  const config = {
    config: {
      file: {
        attributes: {
          controlsList: 'nodownload',
        },
      },
    },
    playIcon: <img src={PlayIcon} alt="playicon" />,
    url: 'https://www.youtube.com/watch?v=jD_Kid4EUAA',
    light:
      'https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/phosphorus-plant-proactive-min.png',
    playing: true,
    controls: true,
    width: '100%',
  };
  return (
    <Layout>
      <SEO
        title="Phosphorus Fertilizer Benefits | Crystal Green | Ostara"
        description="Learn why phosphorus is a critical nutrient for your agricultural yield and how Crystal Green fertilizers optimize nutrient uptake with a phosphorus advantage."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Phosphorus Fertilizer"
      />
      <main>
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box w={['100%', '100%', '100%', '46.5%']}>
                <Stack marginTop={[0, 0, 0, 20]} maxW="50rem" spacing={6}>
                  <h1>Phosphate</h1>
                  <p style={{ maxWidth: 550 }}>
                    Crystal Green fertilizers deliver phosphate all season to
                    help plants meet their potential. Here’s how it works.
                  </p>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../../images/phosphorus-hero-img.jpg"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>

        <Box w="100%" p={4} className="phosphorus-body-container">
          <Container
            className="phosphorus-body-content-1-container"
            maxW={1400}
          >
            <Flex className="phosphorus-body-content-1">
              <StaticImage
                className="hero-img"
                placeholder="transparent"
                src="../../images/graphic.png"
                alt="Ostara graphic"
              />
            </Flex>
            <Flex className="phosphorus-body-content-1">
              <h3>Why Do Plants Need Phosphate?</h3>
              <p>
                The energy that plants use to drive all their physiological
                process are phosphate dependent. In fact, phosphate plays a
                central role in how carbon is captured by a plant. For each
                molecule of glucose created, phosphorus is used 72 times.
              </p>
            </Flex>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container className="phosphorus-body-content-3" maxW={1400}>
            <Box className="phosphorus-body-content-3--heading">
              <h3>Plants Need Phosphate Throughout the Growing Cycle</h3>
            </Box>
            <Container className="phosphorus-curve-line" maxW="container.md" />
            <SimpleGrid
              columns={{ md: 1, lg: 2 }}
              className="phosphorus-data-carousel"
              spacing={6}
            >
              <div className="phosphorus-carousel-wrapper">
                <StaticImage
                  placeholder="transparent"
                  src="../../images/phosphorus-slide1.png"
                  alt="Ostara logo"
                  width={400}
                />
                <h5>Root Growth</h5>
                <p>
                  Early access to phosphorus is critical for the development of
                  a strong root system.
                </p>
              </div>
              <div className="phosphorus-carousel-wrapper">
                <StaticImage
                  placeholder="transparent"
                  src="../../images/phosphorus-slide2.png"
                  alt="Ostara logo"
                  width={400}
                />
                <h5>Crop Development</h5>
                <p>
                  Phosphate from Crystal Green helps crops grow and achieve
                  optimal grain filling and tuber bulking for increased yield at
                  harvest.
                </p>
              </div>
            </SimpleGrid>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container
            className="phosphorus-body-content-2-container"
            maxW={1400}
            marginTop={[50, 100]}
          >
            <Flex className="phosphorus-body-content-2">
              <StaticImage
                className="hero-img"
                placeholder="transparent"
                src="../../images/phosphorous-infographic.png"
                alt="Ostara graphic"
              />
            </Flex>
            <Flex className="phosphorus-body-content-2">
              <h3>What Happens If Plants Don’t Get Enough Phosphate?</h3>
              <p>
                What if your plants look fine but are short on phosphate
                availability due to tie-up or leaching? This is a{' '}
                <strong>hidden hunger zone</strong> that could be costing you
                yield. Unlike conventional fertilizers, phosphate from Crystal
                Green doesn’t tie-up or leach. It remains in the soil when for
                crops to access when they need it. If phosphorus isn’t
                available, growth slows down and yields drop. If phosphorus is
                severely depleted, the shortage will stop yield altogether.
              </p>
            </Flex>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container className="phosphorus-body-content" maxW={1400}>
            <h2>
              Crystal Green’s Phosphate
              <br />
              Advantage
            </h2>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
              <Flex className="phosphorus-body-content-2">
                <h3>Delivering Phosphate All Season Long</h3> <br />
                <p>
                  Crystal Green phosphate fertilizers are sparingly water
                  soluble and only release phosphate in response to organic
                  acids produced by crop roots. Crop Driven Release™ ensures
                  phosphate remains available throughout the season to support
                  crop growth, as well as late carbohydrate production during
                  the grain filling or tuber bulking phase.
                </p>
                <Link to="/product/crystal-green">
                  Learn more about Crystal Green<sup>®</sup>
                </Link>
              </Flex>
              <StaticImage
                placeholder="transparent"
                src="../../images/Graphic-blue-p-season-availabilty.svg"
                alt="Ostara icons"
              />
            </SimpleGrid>
          </Container>
        </Box>

        <Box w="100%" p={4} className="phosphorus-data-container">
          <Container maxW={1400}>
            <h4>THE DATA</h4>
            <h2>More Data & Insights</h2>
            <SimpleGrid
              columns={{ md: 1, lg: 2 }}
              className="phosphorus-data-info"
              spacing={6}
            >
              <ReactPlayer className="module-video" {...config} />
              <Box className="phosphorus-data-content">
                <h5>WEBINAR</h5>
                <h3>Phosphorus: A Plant’s Perspective</h3>

                <p>
                  Learn why phosphorus is essential for plant growth, the
                  agronomy of Crystal Green fertilizer and how growers benefit
                  from this new nutrient technology.
                  <br />
                  <br />
                  Presented by: Dr. Roland Leatherwood, Ostara’s Director of
                  Agronomy & Innovation, and Craig Davidson, President of Taurus
                  Ag.
                </p>
              </Box>
            </SimpleGrid>
          </Container>
        </Box>
      </main>
    </Layout>
  );
};

export default Phosphorus;
